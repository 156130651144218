<template>
  <el-container>
    <el-header><Header /></el-header>
    <el-container>
      <el-aside width="200px"><LeftNav /></el-aside>
      <el-main>
        <slot></slot>
      </el-main>
    </el-container>
  </el-container>
</template>
<script>
import Header from "@/components/header";
import LeftNav from "@/components/LeftNav";
export default {
  components: { Header, LeftNav },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
</style>