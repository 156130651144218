<template>
<div :id="now">
</div>
</template>

<script>
let echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/bar");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");

export default {
    props: ["title", "chartData"],
    data() {
        const now = +new Date() + (this.createCode(6));
        return {
            now
        };
    },
    mounted() {
        this.drawLine();
    },
    watch: {
        'chartData': function () {
            this.drawLine();
        }
    },
    methods: {
        createCode(codeLength) {
            var code = "";
            var codeLength = codeLength || 4;
            var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
                'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z');
            for (var i = 0; i < codeLength; i++) {
                var index = Math.floor(Math.random() * 36);
                code += random[index];
            }
            return code;
        },
        drawLine() {
            if (!this.chartData) return;
            let myChart = echarts.init(document.getElementById(this.now));
            let options = {
                color: ["#FFB74E", "#B39DDB", "#FF8A66", "#80CBC4", "#03A9F5", "#66bb6a", "#7e57c2", "#fdd835"],
                title: {
                    show: !!this.chartDataTitle,
                    text: this.chartDataTitle,
                    left: "center",
                    textStyle: {
                        align: "center"
                    }
                },
                tooltip: {
                    formatter: "{a}{b}：{c}" + (this.unit || '')
                },
                xAxis: [{
                    type: 'category',
                    data: this.chartData.DataType,
                    axisLabel: {
                        interval: 0,
                        rotate: 40
                    }
                }],
                yAxis: [{
                    type: 'value'
                }],
                series: this.chartData.data
            };
            if (this.chartData.data.length == 1) {
                this.chartDataoptions.xAxis[0].data = this.chartData.data[0].value.map(function (item, index) {
                        return {
                            value: item,
                            key: this.chartData.DataType[index]
                        }
                    })
                    .sort(function (a, b) {
                        return b.value - a.value
                    })
                    .map(function (item) {
                        return item.key
                    });
                options.series[0].value.sort(function (a, b) {
                    return b - a;
                })
            }
            for (var i = 0; i < options.series.length; i++) {
                options.series[i].type = "bar";
                options.series[i].data = options.series[i].value;
                options.series[i].label = {
                    normal: {
                        show: true,
                        position: "top",
                        formatter: "{c}" + (this.unit || '')
                    }
                };
            }
            myChart.setOption(options);
        }
    }
};
</script>
