/*jshint esversion:6*/
import {
    $get
} from '@/api';
export default {
    getConsulAppointData(params) {
        return $get('/StatisticalChart/GetConsulAppointChartList', params);
    },
    getFirstCatchData(params) {
        return $get('/StatisticalChart/GetFirstCatchChartList', params);
    },
    GetCustLevelData(params) {
        return $get('/StatisticalChart/GetCustLevelChartList', params);
    },
    GetTotalFeelData(params) {
        return $get('/StatisticalChart/GetTotalFeelChartList', params);
    }
}