<template>
<div :id="now">
</div>
</template>

<script>
let echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/pie");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
require("echarts/lib/component/legend");
require('echarts/lib/component/legend/scrollableLegendAction')
require('echarts/lib/component/legend/ScrollableLegendModel')
require('echarts/lib/component/legend/ScrollableLegendView')
export default {
    props: ["title", "chartData"],
    data() {
        const now = +new Date() + (this.createCode(6));
        return {
            now
        };
    },
    mounted() {
        this.drawLine();
    },
    watch: {
        'chartData': function () {
            this.drawLine();
        }
    },
    methods: {
        createCode(codeLength) {
            var code = "";
            var codeLength = codeLength || 4;
            var random = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R',
                'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z');
            for (var i = 0; i < codeLength; i++) {
                var index = Math.floor(Math.random() * 36);
                code += random[index];
            }
            return code;
        },
        drawLine() {
            if (!this.chartData) return;
            let myChart = echarts.init(document.getElementById(this.now));
            let options = {
                color: ["#FFB74E", "#B39DDB", "#FF8A66", "#80CBC4", "#03A9F5", "#66bb6a", "#7e57c2", "#fdd835"],
                title: {
                    text: this.title,
                    // subtext: n.subtext,
                    left: "center",
                    textStyle: {
                        align: "center"
                    }
                },
                tooltip: {
                    formatter: "{b} {c}人 ({d}%)"
                },
                legend: {
                    type: 'scroll',
                    x: "left",
                    bottom: "0",
                    data: this.chartData.DataType
                },
                series: [{
                    type: "pie",
                    data: this.chartData.DataValue || this.chartData.data,
                    radius: '65%',
                    label: {
                        normal: {
                            show: true,
                            formatter: "{b}\n{d}%"
                        }
                    },
                    labelLine: {
                        normal: {
                            show: false,
                            length: 12,
                            length2: 5
                        }
                    }
                }]
            };
            myChart.setOption(options);
        }
    }
};
</script>
