<template>
<el-menu class="el-menu-demo" mode="horizontal" background-color="#409EFF" text-color="#fff" active-text-color="#fff">
    <el-menu-item style="padding-right:0" index="1">
        <img src="@/assets/logo.png" alt="">
    </el-menu-item>
    <el-menu-item index="2">康策医院客户关系管理系统V8.0</el-menu-item>
    <el-submenu style="float:right" index="2">
        <template slot="title">我的工作台</template>
        <el-menu-item index="2-1">选项1</el-menu-item>
        <el-menu-item index="2-2">选项2</el-menu-item>
        <el-menu-item index="2-3">选项3</el-menu-item>
    </el-submenu>
</el-menu>
</template>

<script>
export default {
    name: "Header",
    data() {
        return {
            activeIndex2: '1',
            message: "header"
        };
    },
    method: {
        handleSelect() {

        }
    }
};
</script>