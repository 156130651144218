<template>
<!-- <el-radio-group v-model="isCollapse" style="margin-bottom: 20px;">
  <el-radio-button :label="false">展开</el-radio-button>
  <el-radio-button :label="true">收起</el-radio-button>
</el-radio-group> -->
<el-menu default-active="1-4-1" class="el-menu-vertical-demo" :unique-opened="true" @open="handleOpen" @close="handleClose" :collapse="isCollapse">
  <el-menu-item index="01"  @click="handleSelect('home','首页')">
    <template slot="title">
      <i class="el-icon-location"></i>
      <span slot="title">首页</span>
    </template>
  </el-menu-item>
  <el-submenu v-for="(item,index) in modules" :key="index" :index="''+index">
    <template slot="title">
      <i class="el-icon-location"></i>
      <span slot="title">{{item.ModuleName}}</span>
    </template>
      <el-menu-item  @click="handleSelect(subItem.Url,subItem.ModuleName)" v-for="(subItem,subIndex) in item.ModuleList" :key="subIndex" :index="index +'-' +subIndex+''">{{subItem.ModuleName}}</el-menu-item>
  </el-submenu>
</el-menu>
</template>
<script>
export default {
  name: "LeftNav",
  data() {
    return {
      isCollapse: false,
      message: "LeftNav.",
      modules: [
        {
          ModuleName: "客户管理",
          Icon: "glyphicon-user",
          Url: "customerManagement",
          Sort: 1,
          ModuleList: [
            {
              ModuleName: "健康档案",
              Icon: "glyphicon-btc",
              Url: "healthrecords",
              Sort: 1
            },
            {
              ModuleName: "会员管理",
              Icon: null,
              Url: "membermanagement.customerlist",
              Sort: 1
            },
            {
              ModuleName: "黑名单管理",
              Icon: null,
              Url: "blackList",
              Sort: 1
            },
            {
              ModuleName: "住院管理",
              Icon: null,
              Url: "inpatient.customer",
              Sort: 1
            },
            {
              ModuleName: "住院来源转诊记录",
              Icon: null,
              Url: "hospitalSourceReferralRecords",
              Sort: 2
            },
            {
              ModuleName: "妇幼关怀",
              Icon: null,
              Url: "maternalAndChildCare",
              Sort: 5
            },
            {
              ModuleName: "体检管理",
              Icon: null,
              Url: "physicalExamination.customerlist",
              Sort: 6
            },
            {
              ModuleName: "卫计调查",
              Icon: null,
              Url: "healthSurvey",
              Sort: 7
            },
            {
              ModuleName: "卫计统计",
              Icon: null,
              Url: "healthStatistics",
              Sort: 8
            },
            {
              ModuleName: "商业保险",
              Icon: null,
              Url: "commercialInsurance",
              Sort: 11
            },
            {
              ModuleName: "眼科服务",
              Icon: null,
              Url: "ophthalmology",
              Sort: 11
            }
          ]
        },
        {
          ModuleName: "客户服务",
          Icon: "glyphicon-heart",
          Url: "customerService",
          Sort: 2,
          ModuleList: [
            {
              ModuleName: "库存管理",
              Icon: null,
              Url: "stockManage.putIn",
              Sort: 1
            },
            {
              ModuleName: "库存统计",
              Icon: null,
              Url: "stock.putIn",
              Sort: 1
            },
            {
              ModuleName: "咨询预约",
              Icon: null,
              Url: "consulting.customerList",
              Sort: 1
            },
            {
              ModuleName: "就诊确认",
              Icon: null,
              Url: "doctorconfirm.appointment",
              Sort: 2
            },
            {
              ModuleName: "客户回访",
              Icon: null,
              Url: "clientreview",
              Sort: 3
            },
            {
              ModuleName: "订单管理",
              Icon: null,
              Url: "order.search",
              Sort: 4
            },
            {
              ModuleName: "收银管理",
              Icon: null,
              Url: "caseManage.settle",
              Sort: 5
            },
            {
              ModuleName: "预约日视图",
              Icon: null,
              Url: "visitByDay",
              Sort: 6
            },
            {
              ModuleName: "医生服务",
              Icon: null,
              Url: "doctor",
              Sort: 7
            },
            {
              ModuleName: "套餐消费",
              Icon: null,
              Url: "setMealConsumption",
              Sort: 8
            },
            {
              ModuleName: "健康监测",
              Icon: null,
              Url: "healthMonitor",
              Sort: 9
            },
            {
              ModuleName: "报单管理",
              Icon: null,
              Url: "declarationManagement",
              Sort: 10
            }
          ]
        },
        {
          ModuleName: "随访服务",
          Icon: "glyphicon-calendar",
          Url: "followUpService",
          Sort: 3,
          ModuleList: [
            {
              ModuleName: "科室随访",
              Icon: null,
              Url: "officeVisit",
              Sort: 1
            },
            {
              ModuleName: "慢病随访",
              Icon: null,
              Url: "chronic.inpatient",
              Sort: 1
            },
            {
              ModuleName: "短信随访",
              Icon: null,
              Url: "messageVisit.messaged",
              Sort: 1
            },
            {
              ModuleName: "满意度调查",
              Icon: null,
              Url: "satisfiedSurvey",
              Sort: 2
            },
            {
              ModuleName: "随访设置",
              Icon: null,
              Url: "followUpSet",
              Sort: 3
            },
            {
              ModuleName: "随访计划",
              Icon: null,
              Url: "visitPlan",
              Sort: 4
            },
            {
              ModuleName: "问卷设置",
              Icon: null,
              Url: "questionSet",
              Sort: 5
            }
          ]
        },
        {
          ModuleName: "短信管理",
          Icon: "glyphicon-envelope",
          Url: "SMSManagement",
          Sort: 4,
          ModuleList: [
            {
              ModuleName: "群发短信",
              Icon: null,
              Url: "sendMessage",
              Sort: 1
            },
            {
              ModuleName: "短信接收",
              Icon: null,
              Url: "receiveMessage",
              Sort: 2
            },
            {
              ModuleName: "短信模板",
              Icon: null,
              Url: "messageTemp",
              Sort: 3
            },
            {
              ModuleName: "短信管理",
              Icon: null,
              Url: "messageManage",
              Sort: 4
            },
            {
              ModuleName: "账号信息",
              Icon: null,
              Url: "userInfo",
              Sort: 6
            }
          ]
        },
        {
          ModuleName: "呼叫中心",
          Icon: "glyphicon-earphone",
          Url: "callCenter",
          Sort: 5,
          ModuleList: [
            {
              ModuleName: "通话记录",
              Icon: null,
              Url: "calllog",
              Sort: 1
            },
            {
              ModuleName: "来电记录",
              Icon: null,
              Url: "callRecords",
              Sort: 4
            }
          ]
        },
        {
          ModuleName: "投诉管理",
          Icon: "glyphicon-pencil",
          Url: "complaintManagement",
          Sort: 6,
          ModuleList: [
            {
              ModuleName: "投诉登记",
              Icon: null,
              Url: "complaintRegistration",
              Sort: 1
            },
            {
              ModuleName: "投诉指派",
              Icon: null,
              Url: "complaintAssignment",
              Sort: 2
            },
            {
              ModuleName: "投诉处理",
              Icon: null,
              Url: "complaintSettling",
              Sort: 3
            }
          ]
        },
        {
          ModuleName: "决策分析",
          Icon: "glyphicon-cloud",
          Url: "decisionAnalysis",
          Sort: 7,
          ModuleList: [
            {
              ModuleName: "客户分析",
              Icon: null,
              Url: "CustomerAnalysis",
              Sort: 1
            },
            {
              ModuleName: "客服分析",
              Icon: null,
              Url: "CustomerServiceReport",
              Sort: 2
            },
            {
              ModuleName: "随访分析",
              Icon: null,
              Url: "FollowupReport",
              Sort: 3
            },
            {
              ModuleName: "投诉分析",
              Icon: null,
              Url: "comolaint",
              Sort: 4
            },
            {
              ModuleName: "短信分析",
              Icon: null,
              Url: "SMSReport",
              Sort: 5
            },
            {
              ModuleName: "呼叫分析",
              Icon: null,
              Url: "CallCenterReport",
              Sort: 6
            },
            {
              ModuleName: "会员分析",
              Icon: null,
              Url: "MemberReport",
              Sort: 7
            }
          ]
        },
        {
          ModuleName: "知识管理",
          Icon: "glyphicon-list-alt",
          Url: "knowledgeBase",
          Sort: 8,
          ModuleList: [
            {
              ModuleName: "知识库管理",
              Icon: null,
              Url: "knowledgemanagement",
              Sort: 1
            },
            {
              ModuleName: "知识库日志",
              Icon: null,
              Url: "knowledgemanagementLog",
              Sort: 2
            }
          ]
        },
        {
          ModuleName: "系统设置",
          Icon: "glyphicon-cog",
          Url: "sysConfig",
          Sort: 9,
          ModuleList: [
            {
              ModuleName: "礼品管理",
              Icon: null,
              Url: "giftManage",
              Sort: 1
            },
            {
              ModuleName: "医生排班",
              Icon: null,
              Url: "doctorPlan",
              Sort: 1
            },
            {
              ModuleName: "疾病管理",
              Icon: null,
              Url: "diseaseManage",
              Sort: 1
            },
            {
              ModuleName: "短信问卷",
              Icon: null,
              Url: "messageQue",
              Sort: 1
            },
            {
              ModuleName: "标签管理",
              Icon: null,
              Url: "lableManagement",
              Sort: 1
            },
            {
              ModuleName: "企业字典",
              Icon: null,
              Url: "companyDir",
              Sort: 1
            },
            {
              ModuleName: "机构设置",
              Icon: null,
              Url: "sysHospital",
              Sort: 1
            },
            {
              ModuleName: "部门设置",
              Icon: null,
              Url: "sysDepartment",
              Sort: 2
            },
            {
              ModuleName: "科室设置",
              Icon: null,
              Url: "sysOffice",
              Sort: 3
            },
            {
              ModuleName: "用户设置",
              Icon: null,
              Url: "sysUserConfig",
              Sort: 4
            },
            {
              ModuleName: "角色管理",
              Icon: null,
              Url: "sysRolemanage",
              Sort: 5
            },
            {
              ModuleName: "系统日志",
              Icon: null,
              Url: "syslog",
              Sort: 6
            },
            {
              ModuleName: "基础信息",
              Icon: null,
              Url: "sysBaseInfo",
              Sort: 8
            },
            {
              ModuleName: "会员等级",
              Icon: null,
              Url: "sysViprank",
              Sort: 9
            },
            {
              ModuleName: "区域维护",
              Icon: null,
              Url: "sysRegionSet",
              Sort: 12
            },
            {
              ModuleName: "版本设置",
              Icon: null,
              Url: "sysVisSet",
              Sort: 14
            },
            {
              ModuleName: "问卷项目",
              Icon: null,
              Url: "questionnaire",
              Sort: 15
            },
            {
              ModuleName: "系统配置",
              Icon: null,
              Url: "systemConfig",
              Sort: 16
            },
            {
              ModuleName: "收费项目",
              Icon: null,
              Url: "payService",
              Sort: 17
            },
            {
              ModuleName: "套餐设置",
              Icon: null,
              Url: "packageSet",
              Sort: 18
            }
          ]
        },
        {
          ModuleName: "微信系统",
          Icon: "glyphicon-phone",
          Url: "WeChat",
          Sort: 11,
          ModuleList: [
            {
              ModuleName: "报单设置",
              Icon: null,
              Url: "declarationSet",
              Sort: 1
            },
            {
              ModuleName: "院内活动",
              Icon: null,
              Url: "hosActivity",
              Sort: 1
            },
            {
              ModuleName: "院内轮播图",
              Icon: null,
              Url: "hosScrollPic",
              Sort: 1
            }
          ]
        }
      ]
    };
  },
  methods: {
    handleClose() {},
    handleOpen() {},
    handleSelect(url,name) {
      this.$store.commit('willAddTab',{title:name,name:url});
      this.$router.push({ name: url });
    }
  }
};
</script>
