<template>
  <Layout>
    <div class="block text-right mb16">
      <el-date-picker
        v-model="date"
        value-format="yyyy-MM-dd"
        type="daterange"
        unlink-panels
        :clearable="false"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :shortcuts="shortcuts"
      >
      </el-date-picker>
      <el-button
        class="ml16"
        type="primary"
        :loading="isLoading"
        @click="search(date)"
        >生成图表</el-button
      >
    </div>
    <el-card shadow="hover" class="box-card">
      <div slot="header" class="clearfix">咨询预约趋势分析</div>
      <LineChart :chart-data="lineData" style="width: 100%; height: 300px" />
    </el-card>
    <el-row class="small-chart" :gutter="20">
      <el-col :span="6">
        <el-card shadow="hover" class="box-card">
          <div slot="header" class="clearfix">咨询预约趋势分析</div>
          <LineChart
            :chart-data="lineData"
            style="width: 100%; height: 300px"
          />
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="hover" class="box-card">
          <div slot="header" class="clearfix">客户来源分析</div>
          <PieChart :chart-data="pieData" style="width: 100%; height: 300px" />
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="hover" class="box-card">
          <div slot="header" class="clearfix">客户等级分析</div>
          <FunnelChart
            :chart-data="funnelData"
            style="width: 100%; height: 300px"
          />
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card shadow="hover" class="box-card">
          <div slot="header" class="clearfix">医疗服务质量分析</div>
          <BarChart :chart-data="barData" style="width: 100%; height: 300px" />
        </el-card>
      </el-col>
    </el-row>
  </Layout>
</template>

<script>
// eslint-disable-next-line
import Layout from "@/components/Layout";
import api from "./dashboard_api";
import LineChart from "@/components/charts/LineChart";
import PieChart from "@/components/charts/pieChart";
import FunnelChart from "@/components/charts/funnelChart";
import BarChart from "@/components/charts/barChart";
export default {
  name: "desktop",
  components: {
    LineChart,
    PieChart,
    FunnelChart,
    BarChart,
    Layout,
  },
  data() {
    const end = new Date();
    const start = new Date();
    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
    return {
      date: [start, end],
      barData: null,
      lineData: null,
      pieData: null,
      funnelData: null,
      isLoading: false,
      shortcuts: [
        {
          text: "最近一周",
          onClick(picker) {
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近一个月",
          onClick(picker) {
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit("pick", [start, end]);
          },
        },
        {
          text: "最近三个月",
          onClick(picker) {
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit("pick", [start, end]);
          },
        },
      ],
    };
  },
  methods: {
    search(date) {
      const params = {
        StartTime: date[0],
        EndTime: date[1],
      };
      this.isLoading = true;
      api.getConsulAppointData(params).then(
        (res) => {
          this.isLoading = false;
          this.lineData = res;
        },
        (res) => {
          this.isLoading = false;
          console.log(res, "err");
        }
      );
      api.getFirstCatchData(params).then(
        (res) => {
          this.isLoading = false;
          this.pieData = res;
        },
        (res) => {
          this.isLoading = false;
          console.log(res, "err");
        }
      );
      api.GetCustLevelData(params).then(
        (res) => {
          this.isLoading = false;
          this.funnelData = res;
        },
        (res) => {
          this.isLoading = false;
          console.log(res, "err");
        }
      );
      api.GetTotalFeelData(params).then(
        (res) => {
          this.isLoading = false;
          this.barData = res;
        },
        (res) => {
          this.isLoading = false;
          console.log(res, "err");
        }
      );
    },
  },
  created() {
    this.search(this.date);
  },
};
</script>

<style scoped>
.small-chart {
  margin-top: 16px;
}
</style>
