<template>
    <div :id="now"></div>
</template>
<script>
let echarts = require("echarts/lib/echarts");
require("echarts/lib/chart/line");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/title");
export default {
  props: ["title", "chartData"],
  data() {
    const now = +new Date() + (this.createCode(6));
    return {
      now
    };
  },
  mounted() {
    this.drawLine();
  },
  watch:{
    'chartData':function(){
      this.drawLine();
    }
  },
  methods: {
     createCode(codeLength){ 
       var code = "";  
       var codeLength =codeLength||  4;  
       var random = new Array(0,1,2,3,4,5,6,7,8,9,'A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R',  
       'S','T','U','V','W','X','Y','Z');  
       for(var i = 0; i < codeLength; i++) {
        var index = Math.floor(Math.random()*36);  
        code += random[index];  
      }  
      return code;
    } ,
    drawLine() {
      if(!this.chartData)return;
      let myChart = echarts.init(document.getElementById(this.now));
      let options = {
        // color:["#FFB74E", "#B39DDB", "#FF8A66", "#80CBC4", "#03A9F5", "#66bb6a", "#7e57c2", "#fdd835"],
        color: ["#17a417", "#ff5400"],
        title: {
          text: this.title,
          show: !!this.title
        },
        tooltip: {},
        xAxis: {
          type: "category",
          boundaryGap: false,
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "#999"
            }
          },
          data: this.chartData.DataType
        },
        yAxis: {
          type: "value",
          show: true,
          min: "0",
          splitNumber: 3,
          minInterval: 1,
          axisLabel: {
            show: true,
            textStyle: {
              color: "#999"
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          splitLine: {
            lineStyle: {
              color: ["#e7e7e7"]
            }
          }
        },
        series: this.chartData.Data
      };
      options.series.forEach((item, index, arr) => {
        arr[index] = {
          ...item,
          type: "line",
          // smooth: true,
          symbolSize :7,
          areaStyle: {
            normal: {
              opacity: 0.1
            }
          }
        };
      });
      myChart.setOption(options);
    }
  }
};
</script>

